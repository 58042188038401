// App.js
import React from 'react';
import './App.css';
import LoginForm from './components/LoginForm';
import Home from './pages/home';
import AdminPage from './pages/admin/AdminPage';
import CrudPublic from './pages/admin/CrudPublic';
import PrivateRoute from './components/PrivateRoute';  // Importamos PrivateRoute
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route 
            path="/home" 
            element={<PrivateRoute element={Home} />}
          />
          {/* Protegemos la ruta de AdminPage con PrivateRoute */}
          <Route 
            path="/admin" 
            element={<PrivateRoute element={AdminPage} />} 
          />
          <Route 
            path="/crud" 
            element={<PrivateRoute element={CrudPublic} />} 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
