import { changePassword, idCliente, UpdateUser } from '../service/usuario'
import { useState } from 'react';
import '../css/ModalChangePass.css';
import Swal from 'sweetalert2';

const ModalChangePass = ({ idClient, onClose }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleClick = async () => {
        if (password !== confirmPassword) {
            alert('Las contraseñas no coinciden');
            return;
        }else{
            const idObj = await idCliente(idClient); // 'idObj' ahora contiene { id: 20 }
        
            if (idObj && idObj.id) {
                const data = await changePassword(idObj.id, password); // Acceder correctamente a id
                const update = await UpdateUser(idObj.id)
                if (data.status === 200 && update.status === 200) {

                    Swal.fire({
                        icon: 'success',
                        title: 'Contraseña cambiada correctamente',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => {
                        onClose();
                    })
                }
            } else {
                console.error('ID no encontrado en la respuesta de idCliente');
            }
        }
    }

    return (
        <div className='modal-overlay'>
            <div className='modal-content-change'>
                <h2>Cambiar contraseña</h2>
                <form>
                    <label htmlFor="password">Nueva contraseña:</label>
                    <input type="password" id="password" name="password" required  onChange={(e) => setPassword(e.target.value)} />
                    <label htmlFor="confirmPassword">Confirmar contraseña:</label>
                    <input type="password" id="confirmPassword" name="confirmPassword" required  onChange={(e) => setConfirmPassword(e.target.value)}/>
                    
                </form>
                <button onClick={handleClick}>Confirmar</button>
            </div>
        </div>
    );
};

export default ModalChangePass;