let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL;


export const getCreditos = async( ) => {
    const response = await fetch(`${BASE_URL}/creditos/getCreditos`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const res = await response.json()
    return res
}

export const UltimoCredito = async( ) => {
    const response = await fetch(`${BASE_URL}/creditos/ultimoCredito`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })
    const res = await response.json()
    return res
}

export const getTipoCreditos = async( CategoryCredit) => {
    const response = await fetch(`${BASE_URL}/creditos/getTipoCreditos`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CategoryCredit: CategoryCredit
        })
    })
    const res = await response.json()
    return res
}
