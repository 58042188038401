import { useEffect, useState } from "react"
import FormattedNumber from "../utils/FormattedNumber"
import FormatedDate from "../utils/FormatedDate"
import '../css/informationBill.css'
import PaymentReceiptModal from "../components/PaymentReceiptModal"
import { getAgencia } from "../service/agencia"
import { getPagos, ultimoPago } from "../service/pagos"
import { useAppContext } from '../AppContext' // Importa el contexto

const InformationBill = ({ accountNumber, creditData }) => {

    const { client, setClient, setActiveComponent } = useAppContext() // Usa el contexto
    const [pagos, setPagos] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [lastPayment, setLastPayment] = useState(null)
    
    // funcion para covertir una fecha en formato yyyy-mm-dd
    const parseLocalDate = (dateString) => {
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day)
    }

    // Evaluar tipo_cuota y calcular fecha modificada
    const calculateAdjustedDate = (fechaInicio, tipoCuota) => {
      let fechaInicioDate = parseLocalDate(fechaInicio)
      if (tipoCuota && tipoCuota === "Diarios") {
        fechaInicioDate.setDate(fechaInicioDate.getDate() + 1) // Sumar un día
      }
      return fechaInicioDate.toISOString().split('T')[0]  // Formato yyyy-mm-dd
    };
  
    const fechaInicio = creditData 
      ? calculateAdjustedDate(creditData.fecha_inicio, creditData.Desembolso.Solicitud.TipoCredito.tipo_cuota)
      : null

    // useEffect para obtener todos los pagos con el numero de credito
    useEffect(() => {
      const fetchgetPagos = async () => {
        try {
          const data = await getPagos(accountNumber)
          setPagos(data)

          const lastPaymentData = await ultimoPago(accountNumber)
          const diaUltimoPago = lastPaymentData[0].fecha_pago
          const [fecha] = diaUltimoPago.split('T')
          const [year, month, day] = fecha.split('-')
          const fechaUltimoPago = `${day}/${month}/${year}`
          
          setLastPayment(fechaUltimoPago)

        } catch (error) {
          console.error(error)
          console.log('There has been a problem with your fetch operation: ' + error)
        }
      }
      fetchgetPagos()
    }, [accountNumber])


    // funcion para mostrar modal de recibo
    const handleShowModal = async (payment) => {

      // Extraer los primeros dos caracteres del comprobante
      const agencyCode = payment.comprobante.substring(0, 2)
    
      try {
        const agencia = await getAgencia(agencyCode)

        // Anexar la agencia al objeto payment
        const updatedPayment = {
          ...payment,
          agencia: agencia, // Añadir los datos de la agencia al payment
        }
    
        // Actualizar el estado con el payment modificado y mostrar el modal
        setSelectedPayment(updatedPayment)
        setShowModal(true)

      } catch (error) {
        console.error('Error fetching agency:', error)
        // Manejar el error de manera adecuada
      }
    }

    // funcion para cerrar modal 
    const handleCloseModal = () => {
      setShowModal(false)
      setSelectedPayment(null)
    };

     // Función para regresar a CreditHistory
     const handleBack = () => {
      setActiveComponent('historial')
    }
    
    return (
      <>
        <div className="conatiner-information">
          <br/>
          <button className="btn btn-secondary" style={{float: 'left', marginLeft: '-80%'}} onClick={handleBack}>Regresar a Historial de Créditos</button>
            <br/>
            <div>
                <h3>Calificacion </h3>
            </div>
            <br/>
            <div>
                <table className="table border">
                    <tbody>
                        <tr>
                            <td colSpan={4} style={{textAlign: 'center'}}>
                                <p>fechas Importantes</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center'}}><strong>Apertura</strong></td>
                            <td style={{textAlign: 'center'}}><strong>Fecha prevista de vencimiento</strong></td>
                            <td style={{textAlign: 'center'}}><strong>Ultimo pago</strong></td>
                            <td style={{textAlign: 'center'}}><strong>Primer pago</strong></td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center'}}><FormatedDate fecha={creditData?.fecha_inicio} /></td>
                            <td style={{textAlign: 'center'}}><FormatedDate fecha={creditData?.fecha_final_prevista} /></td>
                            <td style={{textAlign: 'center'}}>{lastPayment}</td>
                            <td style={{textAlign: 'center'}}><FormatedDate fecha={fechaInicio} /></td>
                        </tr>   
                        <tr>
                            <td colSpan={4} style={{textAlign: 'center'}}>
                                <p>Tipo de credito {creditData?.Desembolso.Solicitud.TipoCredito.nombre}</p>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <br/><br/>
            <div className="table-responsive">
            <table className="table table-striped ">
            <thead>
              <tr>
                <th>Comprobante</th>
                <th>Fecha</th>
                <th>Monto ingresado</th>
                <th>Cobranza pagada</th>
                <th>Empleado de ingreso</th>
                <th>Comprobante de pago</th>
              </tr>
            </thead>
            <tbody>
            
              {pagos.map((pago, index) => (
                <tr key={index}>
                  <td>{pago.comprobante}</td>
                  <td><FormatedDate fecha={pago.fecha_pago} /></td>
                  <td>$ <FormattedNumber value={pago.monto_ingresado || 0} /></td>
                  <td>$ <FormattedNumber value={(pago.monto_ingresado - pago.pago) || 0} /></td>
                  <td>{pago.UsuarioSy.nombre}&nbsp;{pago.UsuarioSy.apellido}</td>
                  <td><button className="btn btn-primary" onClick={() => handleShowModal(pago)}>Ver comprobante</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>

        <PaymentReceiptModal 
          show={showModal}
          handleClose={handleCloseModal}
          paymentDetails={selectedPayment}
          client={client}
        />

      </>
    )
}

export default InformationBill;
