import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppProvider } from './AppContext'; // Asegúrate de que la ruta sea correcta

// Encuentra el contenedor raíz en el HTML
const container = document.getElementById('root');

// Crea el root utilizando createRoot
const root = ReactDOM.createRoot(container);

// Renderiza la aplicación dentro del root
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
