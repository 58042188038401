import React, { useState, useEffect } from 'react';
import '../css/BoxNotice.css';
import notice from '../assets/images/notice.jpeg';
import { PiMoneyWavy } from 'react-icons/pi';
import { sendLike, getControl, sendComment, getComments } from '../service/noticias'; // Importa la función del servicio
import { getImages } from '../service/images';

const BoxNotice = (noticia) => {
  // console.log(noticia);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState(''); // Estado para el nuevo comentario
  const [likes, setLikes] = useState(noticia.noticia.me_gusta || 0); // Estado para manejar los "me gusta"
  const [likeError, setLikeError] = useState(null); // Estado para manejar errores
  const [color, setColor] = useState('');
  const [imagenURL, setImagenURL] = useState('');
  const maxCharacters = 255;

  // Función para obtener comentarios
  const getCommentsAll = async () => {
    try {
      const commentsData = await getComments(noticia.noticia.id_noticia);  // Suponiendo que noticia tiene un campo `id`
      setComments(commentsData);
    } catch (error) {
      console.error('Error al obtener los comentarios:', error);
    }
  };

  useEffect(() => {
    getCommentsAll();

    const VerifyLikeControl = async () => {
      const verifyLike = await getControl(noticia.noticia.id_noticia);  // Suponiendo que noticia tiene un campo `id`
      if (verifyLike.length > 0) {
        setColor('green');
      }
    };

    VerifyLikeControl();

    const fetchImage = async () => {
      try {
        const url = await getImages(noticia.noticia.url_image);
        setImagenURL(url)
      } catch (error) {
        console.error('Error al obtener la imagen:', error);
      }
    }

    fetchImage();
  }, [noticia.noticia.id_noticia, noticia.noticia.url_image]);

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value.length <= maxCharacters) {
      setNewComment(value);
    }
  };

  // Función para agregar comentarios
  const handleAddComment = async (e) => {
    e.preventDefault();

    if (newComment.trim() === '') {
      alert('Comentario vacío');
      return;
    }

    try {
      const commentSend = await sendComment(newComment, noticia.noticia.id_noticia);  // Suponiendo que noticia tiene un campo `id`

      if (commentSend.status === 200) {
        setNewComment(''); // Limpiar el campo de texto después de agregar
        // Llamar a getCommentsAll para actualizar la lista de comentarios
        getCommentsAll();
      } else {
        alert('Error al enviar el comentario');
        console.log('Error al enviar el comentario');
      }

    } catch (error) {
      console.error('Error al enviar el comentario:', error);
    }
  };

  // Función para manejar el clic en el icono de Me gusta
  const handleLikeClickLike = async () => {
    try {
      const likesSend = await sendLike(noticia.noticia.id_noticia);  // Suponiendo que noticia tiene un campo `id`

      if (likesSend.status === 200) {
        setLikes(likes + 1);
        setColor('green');
        setLikeError(null); // Reiniciar cualquier error previo.
      } else if (likesSend.status === 201) {
        setLikes(likes - 1);
        if (likes < 0) {
          setLikes(0);
        }
        setColor('');
        setLikeError(null); // Reiniciar cualquier error previo.
      } else if (likesSend.status === 202) {
        setLikes(likes + 1);
        setColor('green');
        setLikeError(null); // Reiniciar cualquier error previo.
      } else {
        console.log('Error al enviar el "me gusta"');
      }

    } catch (error) {
      console.error('Error al enviar el "me gusta":', error);
      setLikeError('No se pudo registrar tu "me gusta". Intenta de nuevo más tarde.');
    }
  };

  // Función para calcular el tiempo transcurrido
  const getTimeElapsed = (fechaCreacion) => {
    const now = new Date();
    const commentDate = new Date(fechaCreacion);
    const diff = now - commentDate; // Diferencia en milisegundos

    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);
    const days = Math.floor(diff / (3600000 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if(months > 1){
      return '+1 mes';
    }else if (months > 0) {
      return `${months} mes`;
    }else if(weeks  == 4){
      return '1 mes'
    }else if (weeks > 0) {
      return `${weeks} semana`;
    } else if (days > 0) {
      return `${days} día`;
    } else if (hours > 0) {
      return `${hours} hora`;
    } else if (minutes > 0) {
      return `${minutes} minuto`;
    } else {
      return 'justo ahora';
    }
  };

  return (
    <div className="notice">
      <div className="content">
        <div className="user"><strong>@ {noticia.noticia.usuario}</strong></div>
        <div className="post">{noticia.noticia.descripcion}</div> 
        <div className="image"><img src={imagenURL} alt="logo_alter" /></div>
        <div className="like">
          {/* Agregar onClick al div que contiene el icono */} 
          <div className="iconoHeart" onClick={handleLikeClickLike} style={{ color: color }}>
            <PiMoneyWavy className='icono' /><br />Me gusta
          </div>
          <div>{getTimeElapsed(noticia.noticia.fecha_creacion)}</div>
          <div>{likes} me gusta</div> {/* Mostrar el número de "me gusta" */}
          {likeError && <p className="error">{likeError}</p>} {/* Mostrar el mensaje de error si existe */}
        </div>

        {/* Sección de comentarios */}
        <div className="comments-section">
          {comments.map(comment => (
            <div key={comment.id_comentario} className="comment">
              <div className="comment-details">
                <strong>@{comment.nombre_usuario}</strong>
                <p>{comment.comentario}</p>
                <span>{getTimeElapsed(comment.fecha_creacion)}</span> {/* Mostrar el tiempo transcurrido */}
              </div>
            </div>
          ))}

          {/* Formulario para agregar un nuevo comentario */}
          <form onSubmit={handleAddComment} className="comment-form">
            <textarea
              value={newComment}
              onChange={handleInputChange}
              placeholder="Agregar un comentario..."
              maxLength={maxCharacters}
            />
            <div className='counter'>{newComment.length}/{maxCharacters}</div>
            <button type="submit" disabled={newComment.length === 0}>Comentar</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BoxNotice;
