import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../css/PaymentReceiptModal.css';
import alter from '../assets/images/aa.png';
import FormatedDate from '../utils/FormatedDate';
import FormattedNumber from '../utils/FormattedNumber';

const PaymentReceiptModal = ({ show, handleClose, paymentDetails,client }) => {
    
  return (
    <Modal show={show} onHide={handleClose} className='custom-modal-width' >
        <Modal.Header closeButton>
            <Modal.Title>Recibo de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container-modal'>
                <div className='div1'>
                    <img src={alter} alt="logo_alter" />
                    <p className='comprobante'>{paymentDetails?.comprobante}</p>
                </div>
                <div className='div2'>
                    <h3 style={{ fontWeight: 'bold', margin: '0 0 10px 0' }}>AlterCredit, S.A de C.V</h3>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ textDecorationLine: 'underline' }}>Agencia: {paymentDetails?.agencia[0].agencia}</span>
                        <span style={{ textDecorationLine: 'underline' }}>{paymentDetails?.agencia[0].direccion}</span>
                        <span style={{ textDecorationLine: 'underline' }}>Tel: {paymentDetails?.agencia[0].telefono}</span>
                        <span> {<FormatedDate fecha={paymentDetails?.fecha_pago} />}</span>
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <p style={{marginLeft: '30px'}}><strong>Cliente: </strong>{client?.nombre}&nbsp;{client?.apellido}</p>
                <p style={{marginLeft: '30px', marginTop: '-15px'}}><strong>Credito: </strong>{paymentDetails?.credito}</p>
                <p style={{marginLeft: '30px', marginTop: '-15px'}}><strong>Colector: </strong>{paymentDetails?.UsuarioSy.nombre}&nbsp;{paymentDetails?.UsuarioSy.apellido}</p>
                <p style={{marginLeft: '30px', marginTop: '-15px'}}><strong>Concepto: </strong>${<FormattedNumber value={paymentDetails?.monto_ingresado} />}</p>
            </div>
            <div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Recargo</th>
                            <th style={{ textAlign: 'center' }}>Prestamo</th>
                            <th style={{ textAlign: 'center' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center' }}>${<FormattedNumber value={paymentDetails?.cobranza} />}</td>
                            <td style={{ textAlign: 'center' }}>${<FormattedNumber value={paymentDetails?.monto_ingresado} />}</td>
                            <td style={{ textAlign: 'center' }}>${<FormattedNumber value={(paymentDetails?.cobranza + paymentDetails?.monto_ingresado)} />}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal.Body>
    </Modal>
  );
};

export default PaymentReceiptModal;
