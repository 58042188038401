let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getAgencia = async (agencia) => {
    const response = await fetch(`${BASE_URL}/agencia/getAgencia`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            id_agencia: agencia,
        }),
    });

    const res = await response.json();
    return res;
};
