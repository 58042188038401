// AdminPage.jsx
import React from 'react';
import NavMenu from '../../components/NavMenu.jsx';
import { useNavigate } from 'react-router-dom';
import '../../css/admin/adminpage.css';
import Navbar from '../../components/Navbar.jsx';


const AdminPage = ({ rol }) => { // Recibimos `rol` como prop
  const navigate = useNavigate();

  return (
    <>
      <div>
        <br/>
        <Navbar title={'Panel de Administración'}/>
        <NavMenu rol={rol} />  {/* Pasamos el rol a NavMenu */}
        <div className='container-admin'>
      
          <p>Bienvenido al panel de administración.</p>
          <div className='card-list'>
            <div className='card 1' 
              onClick={() => navigate('/crud')} 
              style={{ cursor: 'pointer' }}
            >
              <div className='card_image'>
                {/* Imagen o contenido */}
              </div>
              <div className='card_title title-white'>
                <p>Publicaciones</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
