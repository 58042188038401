import { useEffect, useState } from 'react';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import NavMenu from '../../components/NavMenu.jsx';
import Navbar from '../../components/Navbar.jsx';
import { getNoticias } from '../../service/noticias';
import { Button } from 'react-bootstrap';
import ModalInsertPublic from '../../components/ModalInsertPublic.jsx';
import { updateNoticia } from '../../service/noticias';
import Swal from 'sweetalert2';

const CrudPublic = ({ rol }) => { 
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRow] = React.useState([]);
    const [isVisibleInsert, setIsVisibleInsert] = useState(false);

    const columns = [
        { id: 'id_noticia', label: 'ID', minWidth: 50, maxWidth: 100, align: 'center' },
        { id: 'usuario', label: 'Usuario', minWidth: 250, maxWidth: 300, align: 'center' },
        { id: 'descripcion', label: 'Descripción', minWidth: 150, maxWidth: 200, align: 'center' },
        { id: 'url_image', label: 'URL Imagen', minWidth: 250, maxWidth: 200, align: 'center' },
        { id: 'acciones', label: 'Acciones', minWidth: 250, maxWidth: 300, align: 'center' }
    ];  

    // Función para obtener las noticias
    const fetchNoticias = async () => {
        const data = await getNoticias();
        setRow(data); // Guarda los datos en el estado
    };

    // Llama a fetchNoticias al montar el componente
    useEffect(() => {
        fetchNoticias();
    }, []);

    const handleInsertPublic = () => {
        setIsVisibleInsert(true);
    };

    const handleCloseInsertPublic = () => {
        setIsVisibleInsert(false);
    };

    const handleInsertSuccess = () => {
        // Vuelve a cargar las noticias después de insertar una nueva
        fetchNoticias();
        setIsVisibleInsert(false);  // Cierra el modal después de actualizar la tabla
    };

    const handleDeletePublic = async(id) => {
 
        const deletePublic = await updateNoticia(id);

        if (deletePublic.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Publicación eliminada',
                showConfirmButton: false,
                timer: 1500
            })
            fetchNoticias();
        } else {
            alert('Error al eliminar la publicación');
            console.log('Error al eliminar la publicación');
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
    <>
        <br/>
        <Navbar title={'Mantenimiento de publicación Sistema SAC'} />
        <NavMenu rol={rol} />  {/* Pasamos el rol a NavMenu */}
        <br/>
        <button className='btn btn-primary' onClick={handleInsertPublic}
            style={{ marginLeft: '80px' }}>Insertar publicación</button>
        <br/><br/>
        <Paper sx={{ width: '90%', height: 'auto' , marginLeft: '80px' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, backgroundColor: 'gray', color: '#FFFFFF' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id_noticia}>
                                    {columns.map((column) => {
                                    let value = row[column.id];
                                    if (column.id === 'acciones') {
                                        value = (
                                        <>            
                                            <Button onClick={() => handleDeletePublic(row.id_noticia)} variant="danger">Eliminar</Button>
                                        </>
                                        );
                                    }
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>  

        {isVisibleInsert && (
            <ModalInsertPublic 
                onClose={handleCloseInsertPublic} 
                onInsertSuccess={handleInsertSuccess}  // Aquí pasamos la función para actualizar la tabla
            />
        )}
    </>
  );
};

export default CrudPublic;
