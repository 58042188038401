// NavMenu.js
import React from 'react'
import '../css/NavMenu.css'
import { useNavigate } from 'react-router-dom'
import { HiSquare3Stack3D } from "react-icons/hi2"
import { FcTodoList } from "react-icons/fc"
import { FaMoneyCheckDollar } from "react-icons/fa6"
import { FaHome } from "react-icons/fa"
import { FaPowerOff } from "react-icons/fa"
import { logout } from '../service/usuario'
import { FaUserShield } from "react-icons/fa"



const NavMenu = ({setActiveComponent, rol}) => {
  // inicializacion de funcion navegacion
  const navigate = useNavigate()

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      const response = await logout()
      if (response) {  // Ajusta esta línea según cómo manejes la respuesta del servidor
        if(rol === 1){
          setActiveComponent('home')
          // Redirigir a la página de inicio de sesión
          navigate('/')
        } else {
          // Redirigir a la página de inicio de sesión
          navigate('/')
        }
      } else {
        console.error('Error during logout:', response.message)
      }
    } catch (error) {
      console.error('Network error during logout:', error)
    }
  }

  return (
    <>
      <div className="area"></div>
        <nav className="main-menu">
          <ul>
          {rol === 1 && (
            <>
              <li>
                <a href="#" onClick={() => setActiveComponent('home')}>
                  <FaHome className='fa fa-2x'/>
                  <span className="nav-text">Inicio</span>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => setActiveComponent('historial')}>
                  < HiSquare3Stack3D className='fa fa-2x'/>
                  <span className="nav-text">Historial</span>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => setActiveComponent('request')}>
                  < FaMoneyCheckDollar className='fa fa-2x'/>
                  <span className="nav-text">Solicitar Credito</span>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => setActiveComponent('billing')}>
                  <FcTodoList   className='fa fa-2x'/>
                  <span className="nav-text">Facturacion</span>
                </a>
              </li>
            </>
          )}
          {rol == 2 && (
            <>            
              <li>
                <a href="#" onClick={() => navigate('/home')}>
                  <FaHome className='fa fa-2x'/>
                  <span className="nav-text">Inicio</span>
                </a>
              </li>
              <li>
                <a href="#" onClick={() => navigate('/admin')}>
                  <FaUserShield className='fa fa-2x'/>
                  <span className="nav-text">Panel Admin</span>
                </a>
              </li>
            </>
          )}
          <li>
            <a href="#" onClick={handleLogout}>
              <FaPowerOff className='fa fa-2x'/>
              <span className="nav-text" >Cerrar Sesion</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavMenu;
