
const SIGA_URL = process.env.REACT_APP_SIGA_APP_URL;

export const estado_actual = async(data) => {
    const response = await fetch(`${SIGA_URL}api/estado_actual`, {
        method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id_solicitud: data.id_solicitud,
      credito: data.codigo,
      fecha_ingreso: data.fecha_ingreso
    })

})
    const res = await response.json()
    return res
}



