import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ValidarSesion } from '../general/validarSesion.jsx';
import ModalSession from '../components/ModalSession.jsx';
import { ExtendSession } from '../general/xtendSession.jsx';
import ModalChangePass from '../components/ModalChangePass.jsx'
import CryptoJS from 'crypto-js'
import Loading from '../components/Loading.jsx';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [rol, setRol] = useState(null); // Comenzamos con `null` para manejar el estado de cargado
  const [tiempoDisponible, setTiempoDisponible] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para saber si el usuario está autenticado
  const [loading, setLoading] = useState(true); // Para mostrar un estado de carga
  const [decryptedData, setDecryptedData] = useState(''); // Estado para el dato desencriptado
  const [isModalVisbleChange, setIsModalVisbleChange] = useState(false) // Estado para modal de changePassword

  const navigate = useNavigate();

  // Función para desencriptar los datos
  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_TOKEN_KEY; // Asegúrate de que usas REACT_APP_ para acceder a variables de entorno en React
    
    if (!secretKey) {
      throw new Error('La clave secreta no está definida en el archivo .env');
    }
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const data = await ValidarSesion();
        // console.log(data)
        if (data && data.tiempoDisponible) {
          setTiempoDisponible(data.tiempoDisponible);
          setRol(data.rol);

          // // Aquí desencriptamos el dato y lo guardamos en el estado
          const decrypted = decryptData(data.codigo_cliente); // Ajusta según el campo que tengas que desencriptar
          setDecryptedData(decrypted);

          if(data.primer_ingreso === 0){
            setIsModalVisbleChange(true)
          }

          setIsAuthenticated(true); // Marcar como autenticado si la sesión es válida
        } else {
          setIsAuthenticated(false); // No autenticado si no hay tiempo disponible o no se puede validar
        }
      } catch (error) {
        console.error('Error en la validación de la sesión:', error);
        setIsAuthenticated(false); // No autenticado en caso de error
      } finally {
        setLoading(false); // Finaliza el estado de carga
      }
    };

    checkSession();
  }, []);

  // Control de tiempo restante para la sesión
  useEffect(() => {
    if (tiempoDisponible === null) return; // Evitar ejecutar el intervalo sin un tiempo disponible válido

    const interval = setInterval(() => {
      setTiempoDisponible((prevTiempo) => {
        if (prevTiempo > 0) {
          const newTime = prevTiempo - 1;
          // console.log(newTime)
          // Mostrar el modal si quedan 30 segundos o menos
          if (newTime <= 30 && newTime > 0) {
            setIsModalVisible(true);
          }

          return newTime;
        } else {
          setIsModalVisible(true); // Mostrar el modal cuando el tiempo llegue a 0 si aún no está visible
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [tiempoDisponible]);

  // Función para extender la sesión
  const extendSession = async () => {
    try {
      const response = await ExtendSession();

      if (response.success) {
        setTiempoDisponible(response.nuevoTiempoDisponible); // Actualiza el nuevo tiempo
        setIsModalVisible(false); // Ocultar el modal
      } else {
        console.error('Error al extender la sesión');
        // Si no fue exitoso, cerrar sesión y redirigir al login
        handleLogout();
      }
    } catch (error) {
      console.error('Error al intentar extender la sesión:', error);
    }
  };

  // Mientras la sesión está cargando, mostramos un indicador de carga
  if (loading) {
    return <div>Cargando...</div>;
  }

  // Si no está autenticado, redirigir a la página de login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

   // Función para cerrar sesión y redirigir al login
   const handleLogout = () => {
    setIsAuthenticated(false); // Marcar como no autenticado
    navigate('/'); // Redirigir al login
  };

  // Si está autenticado, renderizar el componente y el modal si es necesario
  return (
    <>
      <Element {...rest} rol={rol} /> {/* Pasamos `rol` al componente protegido */}
      {isModalVisible && (
        <ModalSession
          isVisible={isModalVisible}
          onExtendSession={extendSession}
          onLogout={handleLogout} // Redirige al logout si el usuario decide no extender la sesión
        />
      )}

       {/* Modal para cambiar contraseña por primera vez */}
       {isModalVisbleChange && <ModalChangePass
        idClient={decryptedData}
        onClose={() => setIsModalVisbleChange(false)}
      />}
    </>
  );
};

export default PrivateRoute;
