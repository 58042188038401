import React, { useEffect, useState } from 'react'
import BoxCredit from "../components/BoxCredit"
import '../css/CreditHistory.css'
import FormattedNumber from '../utils/FormattedNumber.js'
import TodayDay from '../utils/TodayDay.js'
import { cuota, Acuerdo, ultimoPago } from '../service/pagos.js'
import { estado_actual } from '../service/siga.js'
import DiffDate from '../utils/DiffDate.js'

const CreditHistory = ({ credits, creditsCanceled, onDetailsClick }) => {
  const [credit, setCredit] = useState(credits)
  const [creditCanceled, setCreditCanceled] = useState(creditsCanceled)
  const [iterationCount, setIterationCount] = useState(0)
  const [DateCanceled, setDateCanceled] = useState()
  const dateFunction = TodayDay()
  const date = dateFunction.substring(0,10)


  useEffect(() => {
    // funcion para llenar campos de cobranza y otros en historial crediticio
    const cobranza = async (id_solicitud, codigo, fecha_ingreso, index) => {
      try {
        const object = {
          id_solicitud: id_solicitud,
          codigo: codigo,
          fecha_ingreso: fecha_ingreso
        }

        const response = await estado_actual(object)
        // guardando datos de estado actual en variable data 
        const data = await response

        // Obtener datos de la cuota
        const factData = await cuota(id_solicitud)
       
        // Evaluando si tiene acuerdo de pago
        const AcuerdoData = await Acuerdo(codigo) 
            
        // Obtener datos de la última fecha de pago
        const UltimoPagoData = await ultimoPago(codigo)

        if (AcuerdoData.length > 0) {
          const diaAcuerdo = AcuerdoData[0].CobroAcuerdoPagos[0].dias // Día de la semana deseado (0=Domingo, 1=Lunes, ..., 6=Sábado)
          const diaUltimoPago = UltimoPagoData[0].fecha_pago
          const [fecha] = diaUltimoPago.split('T')
          const [year, month, day] = fecha.split('-')
          const fechaUltimoPago = `${year}-${month}-${day}` // Formato YYYY-MM-DD para manipulación
      
          // Convertir fechaUltimoPago a un objeto Date
          let fechaObj = new Date(fechaUltimoPago)
          let fechaActual = new Date()

          //semanas trasncurridas desde el ultimo pago hasta la actualidad
          const diferencia = DiffDate(fechaObj, fechaActual)
          const semanas = Math.ceil(diferencia) * 7
          
          // Obtener el día de la semana actual (0 = Domingo, 6 = Sábado)
          const diaSemanaActual = fechaObj.getDay()
          
          // Calcular cuántos días faltan para el próximo `diaAcuerdo` en la semana siguiente
          let diasHastaProximoDia = diaAcuerdo - diaSemanaActual + semanas
  
      
          // Sumar los días calculados a la fecha actual para obtener el próximo día en la semana siguiente
          fechaObj.setDate(fechaObj.getDate() + diasHastaProximoDia)
      
          // Formatear la fecha resultante al formato DD-MM-YYYY
          const dia = String(fechaObj.getDate()).padStart(2, '0')
          const mes = String(fechaObj.getMonth() + 1).padStart(2, '0') // Los meses en JS son 0-indexed
          const anio = fechaObj.getFullYear()
          const fechaProximoDiaFormateada = `${dia}-${mes}-${anio}`
          setDateCanceled(fechaProximoDiaFormateada)
          
      } else {
            // Convertir fechaUltimoPago a un objeto Date
            let fechaObj = new Date()
        
            // Agregar un día a la fecha
            fechaObj.setDate(fechaObj.getDate() + 2)
        
            // Verificar si la nueva fecha es domingo (0 = Domingo)
            if (fechaObj.getDay() === 0) {
                // Si es domingo, pasar al lunes (agregar otro día)
                fechaObj.setDate(fechaObj.getDate() + 3)
            }
        
            // Formatear la fecha resultante al formato DD-MM-YYYY
            const dia = String(fechaObj.getDate()).padStart(2, '0')
            const mes = String(fechaObj.getMonth() + 1).padStart(2, '0') // Los meses en JS son 0-indexed
            const anio = fechaObj.getFullYear()
            const fechaFinal = `${dia}-${mes}-${anio}`
            setDateCanceled(fechaFinal)
        }
      
        // añadir campos a arreglo para visualizacion
        setCredit(prevCredits => {
          const updatedCredits = [...prevCredits];
          updatedCredits[index] = {
            ...updatedCredits[index],
            cobranza: data[1], // Añadir el valor de `data[1]` al crédito
            cuota: factData[0].cuota_diaria
          }
          return updatedCredits
        })
        setIterationCount(prevCount => prevCount + 1) // Incrementa el contador de iteraciones

      } catch (error) {
        console.log(error)
      }
    }

    // recorrido de creditos para evaluacion de creditos cancelados o activos 
    if (iterationCount < credit.length) {  // Si no se han recorrido todos los créditos
      cobranza(
        credit[iterationCount].credito.Desembolso.id_solicitud,
        credit[iterationCount].credito.codigo,
        date,
        iterationCount
      )
    }

  }, [iterationCount, credit, date]) // Se actualiza en cada iteración

  if (!credit || !creditCanceled) {
    return <p>Cargando créditos...</p>
  }

  return (
    <div className="container-history">
      <div className="title">
        <h3>Créditos Activos</h3>
        <div className="box-credit-container">
          {credit.map((credit, index) => (
            <BoxCredit
              key={index}
              accountNumber={credit.credito.codigo}
              creditType={credit.credito.Desembolso.Solicitud.TipoCredito.nombre}
              dayDes={credit.credito.fecha_inicio}
              Debt={<FormattedNumber value={credit.credito.monto_pagar - credit.credito.interes_total} />}
              availableDebt={<FormattedNumber value={credit.saldo_pendiente} />}
              paymentDay={DateCanceled}
              amountPaid={credit.cuota}
              cobranza={<FormattedNumber value={credit.cobranza || 0} />} // Mostrando cobranza aquí
              amountToFinish={'0.00'}
              state={credit.credito.estado}
              isWide={true}
              onDetailsClick={onDetailsClick}
            />
          ))}
        </div>
      </div>
      <br/>
      <h3>Historial de Créditos</h3>
      <div className="History">
        <div className="box-credit-container">
          {creditCanceled.map((credit, index) => (
            <BoxCredit
              key={index}
              accountNumber={credit.credito.codigo}
              creditType={credit.credito.Desembolso.Solicitud.TipoCredito.nombre}
              dayDes={credit.credito.fecha_inicio}
              Debt={<FormattedNumber value={credit.credito.monto_pagar - credit.credito.interes_total} />}
              availableDebt={credit.saldo_pendiente}
              paymentDay={'-'}
              amountPaid={'-'}
              cobranza={<FormattedNumber value={0.00} />}
              amountToFinish={<FormattedNumber value={0.00} />}
              state={credit.credito.estado}
              isWide={true}
              onDetailsClick={onDetailsClick}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CreditHistory
