let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL;

export const solicitarCredito = async (data) => {
    const response = await fetch(`${BASE_URL}/solicitud/solicitarCredito`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id_cliente: data.id_cliente,
            ultimo_credito: data.ultimo_credito,
            ultimo_monto: data.ultimo_monto,
            monto_solicitado: data.monto_solicitado,
            categoria_credito: data.categoria_credito,
            tipo_credito: data.tipo_credito,
            destino: data.destino,
            fecha_desembolso: data.fecha_desembolso,
            telefono: data.telefono,
            fecha_creacion: data.fecha_creacion,
            estado: data.estado
        })
    });
    return await response
}