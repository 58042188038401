import '../css/applyCredit.css'
import { useEffect, useState } from 'react'
import TodayDay from '../utils/TodayDay'
import Swal from 'sweetalert2'
import { UltimoCredito, getTipoCreditos } from '../service/creditos'
import { solicitarCredito } from '../service/solicitud'


const ApplyCredit = ({ client , credits}) => {
    const [selectedCategory, setSelectedCategory] = useState(1)
    const [categoryCredit, setCategoryCredit] = useState([])
    const [montoSolicitado, setMontoSolicitado] = useState('')
    const [montoAnterior, setMontoAnterior] = useState(0)
    const [destinoCredito, setDestinoCredito] = useState('')
    const [fechaDesembolso, setFechaDesembolso] = useState('')
    const [telefono, setTelefono] = useState('')
    const [tipoCredito, setTipoCredito] = useState('')
    const [lastCredit, setLastCredit] = useState('')
    
    
    // funcion para guardar la categoria de credito
    const handleTypeCategory = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
    };
    
    // useEffect para obtener el ultimo credito
    useEffect(() => {
        const lastCredit =  async () => {
            try {
                const data = await UltimoCredito();
                
                setLastCredit(data)
            }catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };
        lastCredit();
    },[])
    
    // useEffect para obtener el tipo de credito
    useEffect(() => {
        const getTypeCredit = async () => {
            try {
                const data = await getTipoCreditos(selectedCategory);
                if (Array.isArray(data)) {
                    setCategoryCredit(data);
                } else {
                    console.error('La respuesta de la API no es un array:', data);
                    setCategoryCredit([]);
                }
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        };

        getTypeCredit();
    }, [selectedCategory]);
    
    // useEffect para llenar campos de solicitud
    useEffect(() => {
        if (lastCredit) {
            // calculando el ultimo monto solicitado
            const calc = (lastCredit.monto_pagar - lastCredit.interes_total).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            
            // asignando valores de tipo de credito y categoria de ultimo_credito
            const idCategoriaCredito = lastCredit.Desembolso.Solicitud.TipoCredito.id_categoria_credito;
            const idCategoryCredit = lastCredit.Desembolso.Solicitud.TipoCredito.id_tipo_credito

            setMontoAnterior(calc);
            setSelectedCategory(idCategoriaCredito)
            setTipoCredito(idCategoryCredit)
            setTelefono(client.telefono)


            // calculando la fecha de desembolso si ha terminado el credito o si esta activo
            if (lastCredit && lastCredit.fecha_final_prevista) {
                const fechaPrevista = new Date(lastCredit.fecha_final_prevista); // Convertir fecha prevista a objeto Date
                const fechaActual = new Date(); // Fecha actual
                let Fecha_Desembolso
    
                if (fechaPrevista < fechaActual) {
                    Fecha_Desembolso = fechaActual.toISOString().split('T')[0]  // Si la fecha ha pasado
                } else {
                    Fecha_Desembolso = fechaPrevista.toISOString().split('T')[0] // Si la fecha no ha pasado
                }
                setFechaDesembolso(Fecha_Desembolso)
            }

        } else {
            setMontoAnterior("0.00");
        }
    }, [lastCredit]);

    // funcion para envio de datos para solicitar credito
    const handleSubmit = async () => {
        try {
           
            const date = TodayDay()

            // creando arreglo para llenar campos de tabla solicitudes
            const object = {
                id_cliente: client.codigo,
                    ultimo_credito: lastCredit.codigo,
                    ultimo_monto: parseFloat(montoAnterior),
                    monto_solicitado: parseFloat(montoSolicitado),
                    categoria_credito: selectedCategory,
                    tipo_credito: tipoCredito,
                    destino: destinoCredito,
                    fecha_desembolso: fechaDesembolso,
                    telefono: telefono,
                    fecha_creacion: date,
                    estado: 1
            }

            const response = await solicitarCredito(object);

            if (response.ok) {
                // Si la respuesta es exitosa
                Swal.fire({
                    title: 'Ingreso Exitoso',
                    text: 'Los datos han sido ingresados correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then(() => {
                    // Recargar la página después de cerrar la alerta
                    window.location.reload()
                });
            } else {
                // Manejar un error en la solicitud
                const errorData = await response.json()
                Swal.fire({
                    title: 'Error',
                    text: `Ocurrió un error: ${errorData.message}`,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
           
        } catch (error) {
            console.error('Error en la solicitud:', error)
            Swal.fire({
                title: 'Error',
                text: 'Ocurrió un error inesperado.',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    return (
        <div className="container-creditApply">
            <h1>Solicitud de prestamo</h1>
            <br /><br />
            <div className="table-container">
                <table className="table apply-table">
                    <tr>
                        <td >
                            <p style={{marginTop: '10px'}}><strong>Monto Solicitado:</strong></p>
                        </td>
                        <td>
                            <div className="input-group" style={{marginLeft: '-10px'}}>
                                <span className="input-group-addon" style={{ padding: '6px 12px', backgroundColor: '#e9ecef', border: '1px solid #ced4da', borderRadius: '0.25rem 0 0 0.25rem' }}>$</span>
                                <input 
                                    type="text" 
                                    min="0" 
                                    className="form-control number-only" 
                                    style={{ width: '130px', border: '1px solid #ced4da', padding: '6px 12px', borderRadius: '0 0.25rem 0.25rem 0' }} 
                                    placeholder="0.00" 
                                    name="monto_solicitado" 
                                    id="monto_solicitado"
                                    value={montoSolicitado}
                                    onChange={(e) => setMontoSolicitado(e.target.value)}
                                />
                            </div>
                        </td>
                        <td>
                            <p style={{marginTop: '10px', marginLeft: '75px'}}><strong>Monto de ultimo credito:</strong></p>
                        </td>
                        <td>
                            <div className="input-group" style={{marginLeft: '-10px'}}>
                                <span className="input-group-addon" style={{ padding: '6px 12px', backgroundColor: '#e9ecef', border: '1px solid #ced4da', borderRadius: '0.25rem 0 0 0.25rem'}}>$</span>
                                <input 
                                    type="text" 
                                    min="0" 
                                    className="form-control number-only" 
                                    style={{ width: '130px', border: '1px solid #ced4da', padding: '6px 12px', borderRadius: '0 0.25rem 0.25rem 0'}} 
                                    placeholder="0.00" 
                                    name="monto_solicitado" 
                                    id="monto_solicitado"
                                    value={montoAnterior}
                                    onChange={(e) => setMontoAnterior(e.target.value)}
                                    readOnly={true}
                                    disabled
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{marginTop: '10px'}}><strong>Categoria del credito:</strong></p>
                        </td>
                        <td>
                            <select 
                                onChange={handleTypeCategory} 
                                className="form-control" 
                                style={{width: '220px'}}
                                value={selectedCategory}
                            >
                                <option value="1" >Tradicional</option>
                                <option value="2">Plus</option>
                                <option value="3">Flash</option>
                                <option value="7">Estacionario</option>
                            </select>
                        </td>
                        <td>
                            <p style={{marginTop: '10px', marginLeft: '75px'}}><strong> Tipo de Credito:</strong></p>
                        </td>
                        <td>
                            <select 
                                className="form-control" 
                                style={{width: '220px'}}
                                value={tipoCredito}
                                onChange={(e) => setTipoCredito(e.target.value)}
                            >
                                <option value="">Seleccione una categoria</option>
                                {Array.isArray(categoryCredit) && categoryCredit.length > 0 ? (
                                    categoryCredit.map((category, index) => (
                                        <option key={index} value={category.id_tipo_credito}>{category.nombre}</option>
                                    ))
                                ) : (
                                    <option value="">No hay tipos de crédito disponibles</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style={{marginTop: '10px'}}><strong>Destino del credito:</strong></p>
                        </td>
                        <td colSpan={3}>
                            <input 
                                type='textarea' 
                                className='form-control' 
                                style={{height: '100px'}}
                                value={destinoCredito}
                                onChange={(e) => setDestinoCredito(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p style={{marginTop: '10px'}}><strong>Fecha Aproximada <br />de desembolso:</strong></p>
                        </td>
                        <td>
                            <input 
                                type="date" 
                                className='form-control' 
                                value={fechaDesembolso}
                                onChange={(e) => setFechaDesembolso(e.target.value)}
                            />
                        </td>
                        <td>
                            <p style={{marginLeft: '85px', marginTop: '10px'}}><strong>Telefono:</strong></p>
                        </td>
                        <td>
                            <input 
                                type="text" 
                                className='form-control' 
                                style={{marginLeft: '-10px'}}
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                                placeholder='ej: 7582-2541'
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <br /><br />
            <button className="btn btn-primary" onClick={handleSubmit}>Solicitar Credito</button>
        </div>
    )
}

export default ApplyCredit
