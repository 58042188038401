let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const ValidarSesion = async() => {
        try{
            const response = await fetch(`${BASE_URL}/usuario/verificarVencimiento`, {
                method: 'GET',
                credentials: 'include'
            });
            const data = await response.json();
           
            return data
        }catch(error){
            console.error('Error en la validación de la sesión:', error);
            return false
        }
}