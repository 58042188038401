import { useState, useEffect } from 'react'
import '../css/billing.css'
import FormattedNumber from '../utils/FormattedNumber'
import { getFacturas } from '../service/pagos'

const Billing = ({ client }) => {
    // obteniendo dia actual 
    var date = new Date().toISOString().substring(0, 7)

    const [selectedDate, setSelectedDate] = useState(date)
    const [facturas, setFacturas] = useState([])

    // funcion para guardar mes de busqueda
    const handleDateChange = (event) => {
      setSelectedDate(event.target.value)
    }

    // funcion para traer todas las facturas carga inicial
    useEffect(() => {
        const fetchFacturas = async () => {
            try {
                const data = await getFacturas()
                setFacturas(data)
            } catch (error) {
                console.log('There has been a problem with your fetch operation: ' + error)
            }
        }
        fetchFacturas()
    }, [])

    // funcion para traer facturas por mes
    const handleSearch = async () => {
        const monthYear = document.getElementById('monthYear').value
        try {
           
            const data = await getFacturas(monthYear)
            setFacturas(data)
        } catch (error) {
            console.log('There has been a problem with your fetch operation: ' + error)
        }
    }

    return (
        <div className="container-billing">
            <h2>Facturas</h2>
            <div className='row' style={{ width: '100%' }}>
                <div className='col-md-2' style={{ float: 'left', marginLeft: '5%' }}>
                    <input
                        type="month"
                        id="monthYear"
                        className="form-control"
                        value={selectedDate}
                        onChange={handleDateChange}
                        min="1900-01"
                        max="2100-12"
                    />
                </div>
                <div className='col-md-2' style={{ float: 'left' }}>
                    <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={handleSearch}
                    >
                        Buscar
                    </button>
                </div>
            </div>
            <br />
            <div className='row' style={{ width: '80%' }}>
                {facturas.length > 0 ? (
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Sello</th>
                                <th scope="col">DTE</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facturas.map((factura) => (
                                <tr key={factura.id_control_factura}>
                                    <td>{factura.mes_aplicacion}</td>
                                    <td>{factura.sello}</td>
                                    <td>{factura.DTE}</td>
                                    <td>${<FormattedNumber value={factura.monto + factura.iva}/>}</td>
                                    <td><button type="button" className="btn btn-primary">Ver Factura</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No se encontraron facturas para la fecha seleccionada.</p>
                )}
            </div>
        </div>
    )
}

export default Billing
