let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getPagos = async (credito) => {
    const response = await fetch(`${BASE_URL}/pagos/getPagos`,{
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            credito: credito
        })
    })
    const res = await response.json()
    return res
}

export const cuota = async (id_solicitud) => {
    const response = await fetch(`${BASE_URL}/pagos/cuota`,{
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id_solicitud: id_solicitud
        })
    })
    const res = await response.json()
    return res
}

export const getFacturas = async (mes_aplicacion) => {
    const response = await fetch(`${BASE_URL}/pagos/getFacturas`,{
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            mes_aplicacion: mes_aplicacion
        })
    })
    const res = await response.json()
    return res
}

export const Acuerdo = async (credito) => {
    const response = await fetch(`${BASE_URL}/pagos/AcuerdoPago`,{
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            credito: credito
        })
    })
    const res = await response.json()
    return res
}

export const ultimoPago = async (credito) => {
    const response = await fetch(`${BASE_URL}/pagos/UltimoPago`,{
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            credito: credito
        })
    })
    const res = await response.json()
    return res
}