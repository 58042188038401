let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const ExtendSession = async() => {
    try{
        const response = await fetch(`${BASE_URL}/usuario/extend-session`, {
        method: 'GET',
        credentials: 'include'
        });

        if (!response.ok) {
        throw new Error('Error al extender la sesión');
        }
        
        const data = await response.json()
        return data

    }catch(error){
        console.error('Error en la validación de la sesión:', error)
        return false
    }
}