import BoxNotice from '../components/BoxNotice';
import { getNoticias } from '../service/noticias';
import { useState, useEffect } from 'react';
import { FiCameraOff } from "react-icons/fi";

const Notice = () => {
  const [post, setPost] = useState([]);


  useEffect(() => {
    const fetchNoticias = async () => {
      const data = await getNoticias();
      setPost(data); // Guarda los datos en el estado
    };

    fetchNoticias(); // Llama a la función para obtener las noticias
  }, []);

  return (
    <div
      className='container-notice'
      style={{
        backgroundColor: '#f5f5f5',
        width: '80%',
        height: '80%', // Fijar la altura del div para que tenga scroll interno
        marginTop: '1%',
        marginLeft: '15%',
        fontFamily: 'Fjalla One',
        overflowY: 'auto', // Esto asegurará que el scroll solo aparezca dentro del div
        padding: '10px',
        boxSizing: 'border-box',
        border: '1px solid #ccc', // Opcional: añade un borde para visualización clara
      }}
    >
      <h3 className='title' style={{ textAlign: 'center' }}>NOTICIAS</h3>
      <br />
      <br />
      {/* Renderizar dinámicamente los BoxNotice según el contenido de `post` */}
      {post.length > 0 ? (
        post.map((noticia, index) => (
          <div key={index}>
            <BoxNotice noticia={noticia} />
            <br />
          </div>
        ))
      ) : (
        <div className="notice"
          style={{
            width: '90%',
            margin: '0 auto',
          }}
        >
          <div className="content"
            style={{
              position: 'relative',
              width: '85%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: '15px',
              borderRadius: '5px',
              marginBottom: '10px',
              border: '1px solid rgb(211, 211, 211)',
              boxShadow: '0 0 0 1px hsl(240deg 30% 28% / 5%), 0 2px 5px 0 hsl(240deg 30% 28% / 10%), 0 1px 1px 0 hsl(0deg 0% 0% / 7%)',
              overflow: 'visible',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',  // Centra horizontalmente
              justifyContent: 'center',  // Centra verticalmente
              height: '200px',  // Añade una altura para que se pueda centrar
            }}
          >
            <FiCameraOff size={100} />
            <h1>No hay noticias</h1>
          </div>
        </div>

      )}
    </div>
  );
};

export default Notice;
