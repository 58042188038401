import '../css/admin/modalInsertPublic.css';
import { subirPublicacion } from '../service/images';
import { insertNoticia } from '../service/noticias';
import { useState } from 'react';
import Swal from 'sweetalert2';
import Upload from '../components/Upload';

const ModalInsertPublic = ({ onClose, onInsertSuccess }) => {
    const [publicacion, setPublicacion] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para el componente de carga

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar el tipo de archivo
        const validFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        if (file && !validFileTypes.includes(file.type)) {
            Swal.fire({
                title: 'Archivo no válido',
                text: 'Solo se permiten imágenes (JPEG, PNG, GIF) o archivos PDF.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        // Activar el estado de carga
        setLoading(true);

        try {
            // Enviar la solicitud al backend
            const response = await subirPublicacion(publicacion, file);

            if (response) {
                const imageUrl = response.imageUrl;
                const dataInsert = await insertNoticia(publicacion, imageUrl);  
                
                if (response && dataInsert.status === 200) {
                    Swal.fire({
                        title: 'Ingreso Exitoso',
                        text: 'Los datos han sido ingresados correctamente.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                    }).then(() => {
                        // Llama a la función onInsertSuccess después de cerrar el modal
                        onInsertSuccess();
                    });
                }
            }
        } catch (error) {
            console.log('Error al subir la publicación:', error);
        } finally {
            // Desactivar el estado de carga
            setLoading(false);
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content-insertPublic" onClick={(e) => e.stopPropagation()}>
                <h2>Insertar Publicación</h2>

                {loading ? (
                    <Upload /> // Mostrar el componente de carga cuando loading sea true
                ) : (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="publicacion">Publicación:</label>
                        <textarea 
                            id="publicacion" 
                            name="publicacion" 
                            rows="5" 
                            cols="50" 
                            value={publicacion}
                            onChange={(e) => setPublicacion(e.target.value)} 
                            required
                        ></textarea>

                        <label htmlFor="file">Archivo (Imagen o PDF):</label>
                        <input 
                            type="file" 
                            id="file" 
                            name="file" 
                            accept="image/*,.pdf"  // Solo imágenes y PDF permitidos
                            onChange={(e) => setFile(e.target.files[0])}
                            required
                        />
                        <button type="submit" className='btn btn-primary'>Insertar</button>
                    </form>
                )}

                {!loading && <button className='btn btn-secondary' onClick={onClose}>Cerrar</button>}
            </div>
        </div>
    );
};


export default ModalInsertPublic;
