import React, { useEffect, useState } from 'react';
import '../css/ModalSession.css';

const ModalSession = ({ isVisible, onExtendSession, onLogout }) => {
  const [time, setTime] = useState(30); // Estado inicial del contador (30 segundos).

  useEffect(() => {
    // Reinicia el contador cada vez que el modal no está visible
    if (!isVisible) {
      setTime(30);
      return;
    }

    // Disminuye el contador cada segundo
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(timer); // Detiene el intervalo cuando llega a 0
          onLogout(); // Cerrar sesión cuando el tiempo se acaba
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // El intervalo ahora funciona correctamente cada 1 segundo

    return () => clearInterval(timer); // Limpia el intervalo cuando el componente se desmonta o isVisible cambia
  }, [isVisible, onLogout]);

  if (!isVisible) return null; // No renderiza el componente si el modal no está visible

  return (
    <div className="modal-overlay">
      <div className="modal-content-xtend">
        <h2>Tu sesión está a punto de expirar</h2>
        <h2>{time}</h2>
        <p>¿Quieres extender tu sesión o cerrar sesión?</p>
        <div className="modal-buttons">
          <button
            onClick={() => {
              if (time > 0) { // Solo extiende si el tiempo no ha expirado
                setTime(30); // Restablece el contador si se extiende la sesión.
                onExtendSession();
              } else {
                onLogout(); // Si el tiempo ha expirado, cerrar sesión
              }
            }}
          >
            Extender Sesión
          </button>
          <button onClick={onLogout}>Cerrar Sesión</button>
        </div>
      </div>
    </div>
  );
};

export default ModalSession;
