let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL;

export const getCliente = async ( ) => {
    const response = await fetch(`${BASE_URL}/clientes/getCliente`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json()
    return data;
};
