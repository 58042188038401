const diferenciaEnSemanas = (fechaInicial, fechaFinal) => {
    // Crear objetos de fecha
    const fecha1 = new Date(fechaInicial)
    const fecha2 = new Date(fechaFinal)
  
    // Obtener la diferencia en milisegundos
    const diferenciaMilisegundos = fecha2 - fecha1
  
    // Convertir de milisegundos a días (1000 ms * 60 s * 60 min * 24 hrs)
    const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24)
  
    // Convertir de días a semanas
    const diferenciaSemanas = diferenciaDias / 7
  
    // Devolver la diferencia en semanas (puedes redondear hacia abajo si solo quieres semanas completas)
    return diferenciaSemanas.toFixed(2) // Redondea a 2 decimales si prefieres
  }

  export default diferenciaEnSemanas