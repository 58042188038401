import '../css/boxCredit.css';

const BoxCredit = ({ accountNumber, creditType, dayDes, Debt, availableDebt, paymentDay, amountPaid, cobranza, amountToFinish, state, isWide, client, onDetailsClick }) => {

  // funcion para ubicar el color del credito 
  const getStyleAndTextByState = (state) => {
    if (state === 1) {
      return { color: 'green', text: 'Activo' }
    } else {
      return { color: 'red', text: 'Cancelado' }
    }
  };
   
  return (
    <>
      <div className={`box-credit ${isWide ? 'wide' : 'narrow'}`}>
        <div className='row'>
          <div className='col-md-3'>
            <h5>Cuenta <strong>{accountNumber}</strong></h5>
          </div>
          <div className='col-md-3'>
            <p>Tipo de crédito: <strong>{creditType}</strong></p>
          </div>
          <div className='col-md-4'>
            <p>Fecha de desembolso: <strong>{dayDes}</strong></p>
          </div>
          <div className='col-md-2'>
            <button 
              className="btn btn-primary" 
              style={{ float: 'right' }} 
              onClick={() => onDetailsClick(accountNumber, client)}
            >
              Detalles
            </button>
          </div>
        </div>
        <div className='row' style={{ marginTop: '10px' }}>
          <div className='col-md-2'>
            <h5><strong>$ {Debt}</strong></h5>
            <p>Monto de Crédito</p>
          </div>
          <div className='col-md-2'>
            <h5><strong>$ {availableDebt}</strong></h5>
            <p>Saldo</p>
          </div>
          <div className='col-md-3'>
            <p style={{ height: '2px' }}>Fecha <strong>{paymentDay}</strong></p>
            <h5 style={{ height: '2px' }}><strong>$ {amountPaid}</strong></h5>
            <p style={{ height: '2px', marginTop: '20px' }}>Próximo pago</p>
          </div>
          <div className='col-md-4'>
            <p style={{ height: '2px' }}>Cobranza pendiente: <strong>$ {cobranza}</strong></p>
            <p style={{ height: '2px' }}>Interés Moratorio pendiente: <strong>$ {amountToFinish}</strong></p>
            <p style={{ height: '2px', marginTop: '20px' }}>Moratorio</p>
          </div>
          <div className='col-md-1'>
            {(() => {
              const { color, text } = getStyleAndTextByState(state);
              return (
                <p style={{ float: 'right', color: color }}>
                  {text}
                </p>
              )
            })()}
          </div>
        </div>
      </div>
    </>
  )
}

export default BoxCredit
