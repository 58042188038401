let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL

export const getNoticias = async () => {
    const response = await fetch(`${BASE_URL}/noticia/getNoticias`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    });
    const res = await response.json();
    return res;
}

export const insertNoticia = async (descripcion, image) => {
    const response = await fetch(`${BASE_URL}/noticia/insertNoticia`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            descripcion: descripcion,
            imagen: image
        }),
    })

    const res = await response
    return res;
}

export const updateNoticia = async (id) => {
    const response = await fetch(`${BASE_URL}/noticia/updateNoticia`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            id: id
        }),
    });
    const res = await response;
    return res;
}

export const getControl = async (id_post) => {
    const response = await fetch(`${BASE_URL}/noticia/getControl`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            id: id_post
        }),
    });
    const res = await response;
    return res;
}

export const sendLike = async (id) => {
    try{
        const response = await fetch(`${BASE_URL}/noticia/sendLike`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                postId: id
            }),
        });

        if (!response.ok) {
            throw new Error(`Error al enviar el like: ${response.status}`);
        }
        const res = await response;
        return res;
    }catch(error){
        console.log('Error al enviar el like:', error);
        throw error
    }
}

export const getComments = async (id) => {
    const response = await fetch(`${BASE_URL}/noticia/getComments`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            postId: id
        }),
    });
    const res = await response.json();
    return res;
}

export const sendComment = async (comment, postId) => {
    try{
        const response = await fetch(`${BASE_URL}/noticia/sendComment`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                comment: comment,
                postId: postId
            }),
        });

        if (!response.ok) {
            throw new Error(`Error al enviar el comentario: ${response.status}`);
        }
        const res = await response;
        return res;
    }catch(error){
        console.log('Error al enviar el comentario:', error);
        throw error
    }
}
