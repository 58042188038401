let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL;

export const subirPublicacion = async (publicacion, image) => {
    console.log(publicacion, image)
    const formData = new FormData();
    formData.append('image', image);
    formData.append('publicacion', publicacion);
    
    try {
        const response = await fetch(`${BASE_URL}/image/upload`, {
            method: 'POST',
            credentials: "include",
            // No necesitas establecer el Content-Type manualmente al usar FormData
            body: formData,  // Asegúrate de que estás pasando el formData aquí, no 'FormData'
        });

        if (!response.ok) {  // Verifica si la respuesta tiene un error
            throw new Error('Error al subir la publicación');
        }

        return await response.json();
    } catch (error) {
        console.error('Error en subir publicación:', error);
        throw error;
    }
}

export const getImages = async (imageName) => {
    try {
      const response = await fetch(`${BASE_URL}/image/${imageName}`, {
        method: 'GET', // Cambiamos a GET
        credentials: "include",
      });
  
      if (!response.ok) {
        throw new Error('Error al obtener la imagen');
      }
  
      const blob = await response.blob(); // Obtén la imagen como un blob
      const imageURL = URL.createObjectURL(blob); // Convierte el blob en una URL
      return imageURL;
    } catch (error) {
      console.error('Error en obtener la imagen:', error);
      throw error;
    }
  };
  

