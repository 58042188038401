import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../service/usuario'
import Swal from 'sweetalert2'
import Loading from '../components/Loading'
import logo from '../assets/images/icono.png'

const LoginForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false); // Nuevo estado para manejar el cargador
  const navigate = useNavigate()

  // funcion para para iniciar sesión
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que los campos no esten vacios
    if(!username || !password){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El usuario o contraseña esta vacio'
      })
      return
    }

    setLoading(true); // Activar el cargador

    try {
      const data = await login(username, password)

      // si retorna status 400 o 401 mostrar error de validacion
      if(data.status == 400 || data.status == 401){
        setLoading(false); // Ocultar el cargador si hay error

        Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El usuario o contraseña son invalidos'
      })
      return
      }

      // si retorna status 200 mostrar exito
      if(data.status == 200){
        setLoading(false); // Ocultar el cargador si hay error

        Swal.fire({
          icon: 'success',
          title: 'Inicio de sesión exitoso',
          text: 'Redirigiendo a la página principal...',
          timer: 2000,
          timerProgressBar: true,
          didClose: () => {
            navigate('/home');  // Redirigir a la pantalla principal
          }
        });
      }      
    } catch (error) {
      setLoading(false); // Ocultar el cargador si hay error

      // mostrar error por medio de sweetalert2 
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El usuario o contraseña son invalidos'
      })
    }
  };

  return (
    <div className="container">
      {loading && <Loading />}
      <div className="screen">
        <div className="screen__content">
          <form className="login" onSubmit={handleSubmit}>
              <img src={logo} alt="Logo" style={{ height: '6vh', width: '6vh', marginTop: '-70%', marginLeft: '25%' }} />
              <h4 className="login__title" style={{ marginTop: '-25%' }}>Sistema Altercredit <br/> de Clientes </h4>
            <div className="login__field">
              {/* <i className="login__icon fas fa-user"></i> */}
              <input 
                type="text" 
                className="login__input" 
                placeholder="Usuario" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
              />
            </div>
            <div className="login__field">
              {/* <i className="login__icon fas fa-lock"></i> */}
              <input 
                type="password" 
                className="login__input" 
                placeholder="Contraseña" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            <button className="button login__submit" type="submit">
              <span className="button__text">Iniciar Sesión</span>
              {/* <i className="button__icon fas fa-chevron-right"></i> */}
            </button>
          </form>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
