let BASE_URL;
BASE_URL = process.env.REACT_APP_API_URL;

export const login = async (username, password) => {
    const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username,
            password
        })
    });
    return response
}

export const fetchWithSessionCheck = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (response.status === 401) {
      // Manejar el caso en que la sesión ha expirado
      return { success: false, message: 'Sesión expirada' };
    }
    return response.json();
  } catch (error) {
    console.error('Error en la petición:', error);
    return { success: false, message: 'Error en la petición' };
  }
};

export const logout = async () => {
  const response = await fetch(`${BASE_URL}/login/logout`, {
      method: 'POST',
      credentials: 'include'
  });
  return response.json();
}

export const changePassword = async (idClient, newPassword) => {
    const response = await fetch(`${BASE_URL}/login/changePwd`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id_usuario: idClient,
        newpaswd: newPassword
      })
    });
    return response
}

export const idCliente = async (codigo_cliente) => {
    const response = await fetch(`${BASE_URL}/usuario/idCliente`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        codigo_cliente: codigo_cliente
      })
    });
    return response.json()
}

export const UpdateUser = async (id_codigo) => {
    const response = await fetch(`${BASE_URL}/usuario/updateUsuario`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id_codigo:id_codigo
      })
    });
    return response
}