// src/components/Navbar.js
import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './../css/navbar.css'
import logo from '../assets/images/icono.png'

const Navbar = ({ title, client }) => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        {/* Reemplazar <Link> con un <div> para que no sea un enlace */}
        <div className="navbar-brand">
          <h1>{title}</h1>
        </div>
        <div className="navbar-message">
          <h4>{client}</h4>
        </div>
        <div className="navbar-brand">
          <img src={logo} alt="Logo" style={{ height: '6vh', width: '6vh' }} />
        </div>
      </div>
    </nav>
  );
};


export default Navbar
