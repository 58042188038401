// src/AppContext.js

import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState('inicio');
  const [accountNumber, setAccountNumber] = useState(null);
  const [client, setClient] = useState(null);

  return (
    <AppContext.Provider value={{ activeComponent, setActiveComponent, accountNumber, setAccountNumber, client, setClient }}>
      {children}
    </AppContext.Provider>
  );
};
