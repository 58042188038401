import React from 'react';
import '../css/Upload.css'; // Importamos el archivo de estilos CSS

const Loading = () => {
  return (
    <div className='loading-overlay-upload'>
      <div className='wrapper'>
        <section className='loaders loaders-bg-4'>
          <span className='loader loader-bars'>
            <span></span>
          </span> 
          Subiendo...
        </section>
      </div>
    </div>
  );
};

export default Loading;