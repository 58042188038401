const FormattedNumber = ({ value }) => {

  if(value == undefined) return <span>0</span>
  const formatNumber = (number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return <span>{formatNumber(value)}</span>;
};

export default FormattedNumber;

