import React from 'react';

const FechaFormateada = ({ fecha }) => {

  if(fecha === undefined) return null

  const formatearFecha = (fecha) => {
    const fechaObj = new Date(fecha);
    return fechaObj.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return <span>{formatearFecha(fecha)}</span>;
};

export default FechaFormateada;
