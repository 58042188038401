import React, { useEffect, useState } from 'react'
import Navbar from './../components/Navbar'
import NavMenu from '../components/NavMenu'
import CreditHistory from '../pages/CreditHistory'
import Billing from '../pages/Billing'
import InformationBill from '../pages/InformationBill'
import ApplyCredit from '../pages/ApplyCredit'
import '../css/home.css';
import { getCreditos } from '../service/creditos.js'
import { getCliente } from '../service/cliente.js'
import { useAppContext } from '../AppContext'
import { useNavigate } from 'react-router-dom'
import { logout } from '../service/usuario.js'
import Notice from '../pages/Notice.jsx'


const Home = ({rol}) => {
  // inicializado variable de navegacion
  const navigate = useNavigate()

  const { activeComponent, setActiveComponent, accountNumber, setAccountNumber, client, setClient } = useAppContext()
  const [credits, setCredits] = useState([])
  const [creditsCanceled, setCreditsCanceled] = useState([])
  const [error, setError] = useState(null)
  const [message, setMessage] = useState('Bienvenido/a')
  const [messageClient, setMessageClient] = useState('Bienvenido/a')
  const [nombre, setNombre] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [selectedCreditData, setSelectedCreditData] = useState(null)

  // Función para obtener el cliente
  const fetchCliente = async () => {
    try {
      const data = await getCliente()
      if (data && data.cliente) {
        setClient(data.cliente)
        
        // obteniendo el primer nombre
        const nombreCompleto = data.cliente.nombre || 'Usuario'
        const primerNombre = nombreCompleto.split(' ')[0]
        setNombre(primerNombre)

        // obteniendo el primer apellido
        const apellidosCompleto = data.cliente.apellido || ''
        const apellidos = apellidosCompleto.split(' ')[0]
        setApellidos(apellidos)

        // Mensaje de bienvenida con nombre y apellido del cliente
        setMessage(`Bienvenido/a ${primerNombre} ${apellidos}`)
      } else {
        throw new Error('Cliente data is null or undefined')
      }
    } catch (error) {
      setError(error)
      console.error('Error al obtener el cliente:', error)
    }
  }

  // Función para obtener los créditos
  const fetchCreditos = async () => {
    try {
      const data = await getCreditos()

      if (data) {
        // filtro para obtener solo los creditos activos
        const filteredCredits = Object.entries(data)
          .filter(([key, value]) => value.credito.estado === 1)
          .map(([key, value]) => ({ ...value, key }))
          
        // filtro para obtener solo los creditos cancelados
        const canceledCredits = Object.entries(data)
          .filter(([key, value]) => value.credito.estado !== 1)
          .map(([key, value]) => ({ ...value, key }))

        // Actualizar arreglo con los creditos activos y cancelados
        setCredits(filteredCredits)
        setCreditsCanceled(canceledCredits)
      } else {
        throw new Error('Credito data is null or undefined')
      }
    } catch (error) {
      setError(error)
      console.error('Error al obtener los créditos:', error)
    }
  }

  // useEffect de activacion de funcion 
  useEffect(() => {
    fetchCliente()
    fetchCreditos()

  }, [setClient])

  // Función para manejar el clic en los detalles de un crédito
  const handleDetailsClick = (accountNumber) => {
    setAccountNumber(accountNumber)
    setActiveComponent('informationBill')
  }

  // Actualiza el mensaje según el componente activo
  useEffect(() => {
    switch (activeComponent) {
      case 'historial':
        setMessage('Historial de créditos')
        setMessageClient(`Bienvenido/a ${nombre} ${apellidos}`)
        break
      case 'billing':
        setMessage('Facturación')
        setMessageClient(`Bienvenido/a ${nombre} ${apellidos}`)
        break
      case 'informationBill':
        setMessage(`Detalles de la cuenta ${accountNumber}`)
        setMessageClient(`Bienvenido/a ${nombre} ${apellidos}`)
        break
      case 'request':
        setMessage('Solicitar crédito')
        setMessageClient(`Bienvenido/a ${nombre} ${apellidos}`)
        break
      case 'home':
      default:
        setMessage(`Bienvenido/a ${nombre} ${apellidos}`)
        setMessageClient()

        break
    }
  }, [activeComponent, client, accountNumber])

  // Detectar crédito seleccionado según el número de cuenta
  useEffect(() => {
    if (accountNumber && (credits || creditsCanceled)) {
      const foundCredit = credits?.find(credit => credit.key === accountNumber)?.credito ||
                          creditsCanceled?.find(credit => credit.key === accountNumber)?.credito
      setSelectedCreditData(foundCredit || null)
    }
  }, [accountNumber, credits, creditsCanceled])


  // Renderizar los componentes activos
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'historial':
        return <CreditHistory credits={credits} creditsCanceled={creditsCanceled} client={client} onDetailsClick={handleDetailsClick} />
      case 'billing':
        return <Billing client={client} />
      case 'informationBill':
        return <InformationBill accountNumber={accountNumber} client={client} creditData={selectedCreditData} />
      case 'request':
        return <ApplyCredit client={client} credits={credits}/>
      case 'home':
      default:
        return <Notice />
    }
  }

  // Función para cerrar sesión y redirigir al login
  const Handlelogout = async () => {
    try {
      const response = await logout();
      if (response) {  
        // Redirigir a la página de inicio de sesión
        navigate('/');
      } else {
        console.error('Error during logout:', response.message);
      }
    } catch (error) {
      console.error('Network error during logout:', error);
    }
  };

  return (
    <>
      <div className="home-container">
        <div className="nav-menu-wrapper">
          <NavMenu setActiveComponent={setActiveComponent} rol={rol} />
        </div>
        <div className="content-wrapper">
          <Navbar title={message} client={messageClient} />
          {renderActiveComponent()}
        </div>
      </div>
    </>
  );
};

export default Home
